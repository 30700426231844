import callApi from '../utils/callApi';
import { baseUrl } from '../utils/config';
import {
    REQUEST_BATCH_DETAILS,
    REQUEST_SET_CURRENT_BATCH,
    REQUEST_SET_ACTIVE_BATCH,
    REQUEST_ADD_NEW_BATCH,
    REQUEST_EDIT_BATCH,
    REQUEST_CLOSE_BATCH_JOBS,
    REQUEST_BATCH_JOBS_LIST
} from './actionTypes';

const getBatchDetails = data => (dispatch) => {
    let url = `${baseUrl}/batchdetails`;

    const params = {
        dispatch,
        method: 'GET',
        url,
        actionType: REQUEST_BATCH_DETAILS,
    };
    return callApi(params);

}

const setCurrentBatch = data => (dispatch) => {
    let url = `${baseUrl}/batchdetails/flags`;
    const { batchId, isCurrentBatch } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (batchId === null || batchId === undefined || isCurrentBatch === null || isCurrentBatch === undefined) {
        return Promise.reject(err);
    }
    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'PATCH',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_SET_CURRENT_BATCH,
    };
    return callApi(params);
}

const setActiveBatch = data => (dispatch) => {
    let url = `${baseUrl}/batchdetails/flags`;
    const { batchId, isActiveBatch } = data;
    const err = {
        data: '',
        error: true,
        message: 'Missing required fields.',
    };
    if (batchId === null || batchId === undefined || isActiveBatch === null || isActiveBatch === undefined) {
        return Promise.reject(err);
    }
    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'PATCH',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_SET_ACTIVE_BATCH,
    };
    return callApi(params);
}

const addNewBatch = data => (dispatch) => {
    let url = `${baseUrl}/batchdetails`;
    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'POST',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_ADD_NEW_BATCH,
    };
    return callApi(params);
}

const editBatch = data => (dispatch) => {
    let url = `${baseUrl}/batchdetails`;
    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'PATCH',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_EDIT_BATCH,
    };
    return callApi(params);
}

const closeJobBatch = data => (dispatch) => {
    let url = `${baseUrl}/jobs/closeAllJobsUnderBatch`;
    const params = {
        headers: {
            'content-type': 'application/json',
        },
        dispatch,
        method: 'PATCH',
        url,
        body: JSON.stringify(data),
        actionType: REQUEST_CLOSE_BATCH_JOBS,
    };
    return callApi(params);
}

const getJobsByBatch = batchName => (dispatch) => {
    let url = `${baseUrl}/jobs/getJobsByBatch/${batchName}`;

    const params = {
        dispatch,
        method: 'GET',
        url,
        actionType: REQUEST_BATCH_JOBS_LIST,
    };
    return callApi(params);

}

const getNpBatchList = () => (dispatch) => {
    let url = `${baseUrl}/batchdetails/activebatches`;

    const params = {
        dispatch,
        method: 'GET',
        url,
        actionType: 'REQUEST_GET_NP_BATCH_LIST',
    };
    return callApi(params);

}

export { getBatchDetails, setCurrentBatch, setActiveBatch, addNewBatch, editBatch, closeJobBatch, getJobsByBatch, getNpBatchList };